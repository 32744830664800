import { getUserToken, supabase } from '~/services/supabase'
import { reportError } from '~/services/errors'
import { CreateSeriesPayload, CreateSeriesResponse, DeleteSeriesPayload, UpdateSeriesPayload } from '~/types/database/series'
import { Tables } from '~/types/supabase'
import { SeriesRowProps } from '~/components/series-row/series-row.interfaces'
import { filterDeletedEntries } from '~/helpers/database-conversions'
import { getChapters } from '~/services/database/chapters'
import { getCollaborators } from '~/services/database/collaborators'
import { Role } from '~/services/database/permissions'

const createSeries = async (payload: CreateSeriesPayload) => {
  const token = await getUserToken()
  if (token){
    const data = await fetch('/api/series/create', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      },
      body: JSON.stringify(payload)
    })

    const result: CreateSeriesResponse = await data.json()
    if (result.error) {
      const error = new Error(result.error.message)
      reportError(error)
      throw error
    }

    return result
  }
}

const updateSeries = async (payload: UpdateSeriesPayload) => {
  const token = await getUserToken()
  if (token) {
    const data = await fetch('/api/series/update', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      },
      body: JSON.stringify(payload)
    })

    const result: CreateSeriesResponse = await data.json()
    if (result.error) {
      const error = new Error(result.error.message)
      reportError(error)
      throw error
    }

    return result
  }
}

const deleteSeries = async (payload: DeleteSeriesPayload) => {
  const { data, error } = await supabase
    .from('series_deletions')
    .insert({
      series_id: payload.id
    })
  if (error) {
    reportError(error)
    throw error
  }
  return data
}

const getSeries = async (id: Tables<'series'>['id']) => {
  const { data, error } = await supabase
    .from('series')
    .select('*, series_permissions(*)')
    .eq('id', id)
    .eq('series_permissions.series_id', id)
    .maybeSingle()

  if (error) {
    reportError(error)
    throw error
  }
  return data
}

const getSeriesList = async () => {
  const { data, error } = await supabase
  .from('series')
  .select('*, chapters(count), deleted:series_deletions(count)')
  .order('created_at', { ascending: false })
  .returns<SeriesRowProps['row'][]>()
  if (error) {
    reportError(error)
    throw error
  }
  const series = data ? filterDeletedEntries<SeriesRowProps['row']>(data) : []
  return series
}

const getLastChapter = async (id: Tables<'series'>['id']) => {
  const chapters = await getChapters(id)
  if(chapters.length){
    const lastChapter = chapters.sort((a, b) => b.index - a.index)[0]
    return lastChapter
  }else{
    return null
  }
}

const getStartingStep = async (id: Tables<'series'>['id']) => {
  const collaborators = await getCollaborators(id)()
  const roles = collaborators.map(entry => new Role(entry.permission)) 
  roles.sort((a, b) => {
    return a.index - b.index
  })
  console.log({
    id,
    roles
  })
  const role = roles[0].value === 'admin' ? new Role('translation') : roles[0]
  return role
}

export {
  createSeries,
  updateSeries,
  deleteSeries,
  getSeries,
  getSeriesList,
  getLastChapter,
  getStartingStep
}